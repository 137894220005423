import React, { useCallback, useState } from 'react';
import Papa from 'papaparse';
import { toSnakeCase } from 'utils/index';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { Box, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '../Card';
import DragDropFile from '../DragDropFile';
import Table from './table';
import TableToolbar from './tableToolbar';
import axios from 'utils/axios';
import moment from 'moment';
import './style.scss';

const BulkBoostListing = () => {
  const [disabled, setDisabled] = useState(false);
  const [validOrders, setValidOrders] = useState([]);
  const [validOrdersResponse, setValidOrdersResponse] = useState();
  const [invalidOrders, setInvalidOrders] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('propertyId');
  const [expanded, setExpanded] = useState('validOrders');
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    onClear();
    setSelectedFile(acceptedFiles[0]);

    Papa.parse(acceptedFiles[0], {
      complete: async (result) => {
        try {
          const requiredParams = ['property_id', 'entity_id', 'services', 'method_of_access'];

          if (result.data.length === 0) {
            setHasError(true);
            setMessage({ messageType: 'negative', description: 'File is empty' });
          } else {
            const validOrderList = [];
            const invalidOrderList = [];

            result.data.forEach((order) => {
              try {
                getServicesFromOrder(order);
              } catch (error) {
                invalidOrderList.push(order);
                return true;
              }

              if (!requiredParams.every((key) => key in order && !!order[key])) {
                invalidOrderList.push(order);
              } else {
                validOrderList.push(order);
              }
            });

            if (invalidOrderList.length > 0) {
              setHasError(true);
              setMessage({
                messageType: 'negative',
                description: `Missing required parameters in CSV file. Required params ${requiredParams.join(', ')}.`,
              });
            }

            setValidOrders(validOrderList);
            setInvalidOrders(invalidOrderList);
          }
        } catch (error) {
          setHasError(true);
          setMessage({ messageType: 'negative', description: 'There was an error uploading the file' });
        } finally {
          setDisabled(false);
        }
      },
      header: true,
      transformHeader: (header) => toSnakeCase(header),
      skipEmptyLines: true,
    });
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedRows = Object.values(validOrdersResponse ?? {})
        .filter((order) => order.isDuplicated)
        .map((order) => order.property_id);
      setSelectedOrders(newSelectedRows);
      return;
    }

    setSelectedOrders([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, propertyId) => {
    const selectedIndex = selectedOrders.indexOf(propertyId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedOrders, propertyId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedOrders.slice(1));
    } else if (selectedIndex === selectedOrders.length - 1) {
      newSelected = newSelected.concat(selectedOrders.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedOrders.slice(0, selectedIndex), selectedOrders.slice(selectedIndex + 1));
    }

    setSelectedOrders(newSelected);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onClear = () => {
    setSelectedFile(undefined);
    setValidOrders([]);
    setInvalidOrders([]);
    setSelectedOrders([]);
    setValidOrdersResponse(undefined);
    setHasError(false);
    setMessage('');
    setExpanded('validOrders');
  };

  const getDuplicatedOrderMessage = (order) => {
    const duplicatedOrderMessages = order.duplicatedOrders.map((value) => (
      <Tooltip title={moment(value.createdAt).format('LLL')} key={value.orderId}>
        <p>
          status: <b>{value.status}</b> created at: <b>{moment(value.createdAt).format('ll')}</b>
        </p>
      </Tooltip>
    ));

    const duplicatedTitle = (
      <>
        <span>Duplicate order</span>
        <br />
      </>
    );

    return [duplicatedTitle, ...duplicatedOrderMessages];
  };

  const getErrorMessage = (order) => {
    if (order.message) {
      return order.message;
    } else if (order.details) {
      return order.details.reduce((error, detail) => `${error} ${detail.message}`, '');
    }
    return 'Unknown error';
  };

  const onSubmit =
    ({ allowDuplicates, vendor }) =>
    async () => {
      try {
        setHasError(false);
        setMessage('');

        const validated = validOrders.map((order) => {
          const services = getServicesFromOrder(order);
          return {
            ...order,
            allow_duplicates: allowDuplicates,
            services,
          };
        });

        setIsLoading(true);

        const { data } = await axios.post(`/api/admin/order/${vendor}`, { orders: validated });

        const ordersResponse = {};

        data.successfulOrders.forEach((order) => {
          ordersResponse[order.property_id] = {
            ...order,
            success: true,
            message: 'Success',
          };
        });

        data.failedOrders.forEach((order) => {
          ordersResponse[order.property_id] = {
            ...order,
            success: false,
            isDuplicated: order.duplicatedOrders?.length > 0,
            message: order.duplicatedOrders ? getDuplicatedOrderMessage(order) : getErrorMessage(order),
          };
        });

        setValidOrdersResponse(ordersResponse);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

  const getServicesFromOrder = (order) => {
    const rawServices = order.services.split(';');

    const services = rawServices
      .map((service) => {
        service = service.trim();
        const match = service.match(/^([a-zA-Z0-9_ ]+?)\s*\((\d+)\)$/);
        if (match) {
          const textPart = match[1];
          const numberPart = match[2];
          return {
            type: textPart,
            quantity: parseInt(numberPart, 10),
          };
        } else {
          return null;
        }
      })
      .filter((itemExists) => !!itemExists);
    return services;
  };

  const downloadTemplate = () => {
    const fileUrl = 'assets/bulk_ordering_template.csv';

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'bulk_ordering_template.csv';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const handleExportDuplicated = () => {
    Papa.parse(selectedFile, {
      complete: async (result) => {
        const head = result.meta.fields.join(result.meta.delimiter);
        const duplicatedOrders = result.data.filter((order) => selectedOrders.includes(order.property_id));
        const body = duplicatedOrders.map((order) => {
          const rowValues = result.meta.fields.map((key) => order[key]);
          return rowValues.join(result.meta.delimiter);
        });
        const csvData = [head, ...body];
        const csvString = csvData.join(result.meta.linebreak);
        downloadCSV(csvString);
      },
      header: true,
      transformHeader: (header) => toSnakeCase(header),
      skipEmptyLines: true,
    });
  };

  const downloadCSV = (data) => {
    const csvData = new Blob([data], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `duplicated-orders.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Card>
      <Box mb={2}>
        <Button variant="contained" color="primary" onClick={downloadTemplate}>
          Download template
        </Button>
      </Box>

      <DragDropFile
        title="Drag and drop your CSV file here to start uploading"
        disabled={disabled}
        message={message}
        onDismissMessage={() => {
          setMessage(undefined);
        }}
        hasError={hasError}
        onDrop={onDrop}
      />

      {invalidOrders.length ? (
        <Accordion expanded={expanded === 'invalidOrders'} onChange={handleChange('invalidOrders')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
            <Typography>Properties with issues that will not be submitted ({invalidOrders.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table
              order={'asc'}
              orderBy={'propertyId'}
              columnData={invalidOrders}
              selectedOrders={selectedOrders}
              handleSelectAllClick={handleSelectAllClick}
              handleRequestSort={handleRequestSort}
              handleClick={handleClick}
            />
          </AccordionDetails>
        </Accordion>
      ) : null}

      <TableToolbar
        numSelected={selectedOrders.length}
        onExportDuplicated={handleExportDuplicated}
        title={selectedFile?.name}
        onClear={onClear}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
      <Table
        order={order}
        orderBy={orderBy}
        columnData={validOrders}
        selectedOrders={selectedOrders}
        validOrdersResponse={validOrdersResponse}
        handleSelectAllClick={handleSelectAllClick}
        handleRequestSort={handleRequestSort}
        handleClick={handleClick}
      />
    </Card>
  );
};

export default BulkBoostListing;
