import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Box, Flex } from 'grid-styled';
import { Button } from '@material-ui/core';
import Form from './Form';
import backIcon from '../../../icons/backArrow.svg';

class EditSpecial extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  state = {
    special: null,
    notificationMsg: '',
  };

  render() {
    return (
      <Box pb={100}>
        <Flex>
          <Box pl="2%" pb={20} pt={20}>
            <Link to={`/leasing-specials`} style={{ color: 'var(--green)' }}>
              <img src={backIcon} alt="" /> Back to All Leasing Specials
            </Link>
          </Box>
        </Flex>
        <Flex>
          <Box pl="2%" pb={20}>
            <h2>Create Leasing Special</h2>
            <p>
              Upload a CSV file with the details of move in specials to have the description of each property to be
              automatically updated with the leasing special details.
            </p>
          </Box>
        </Flex>
        <Flex>
          <Box pl="2%" pb={20}>
            <a href="/assets/leasing_special_template.csv" download>
              <Button variant="contained" color="primary">
                Download CSV template
              </Button>
            </a>
          </Box>
        </Flex>
        <Form />
      </Box>
    );
  }
}

export default withRouter(EditSpecial);
